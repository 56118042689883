a:link, a:visited {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    border-radius: 6px;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  
  a:hover, a:active {
    background-color: rgba(0,0,0, 0.15);
  }

  .scroll {
    overflow-x: auto;
  }

.MuiTabs-fixed {
  overflow-x: auto !important;
}